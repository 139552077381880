<template>
  <div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-md-3">
                <label>Start Date</label><br>
                <date-picker v-model="filter.start_date" value-type="YYYY-MM-DD" :placeholder="filter.start_date"></date-picker>
            </div>
            <div class="col-md-3">
                <label>End Date</label><br>
                <date-picker v-model="filter.end_date" value-type="YYYY-MM-DD" :placeholder="filter.end_date"></date-picker>
            </div>
            <div class="col-md-3">
                <label>Trader</label><br>
                <div class="row">
                    <div class="col-12">
                        <v-select
                            v-model="filter.trader_id"
                            :options="users"
                            :reduce="option => option.id"
                            @search="onSearchUser"
                        >
                            <template slot="no-options">
                            Type user name, code, or email..
                            </template>
                        </v-select>
                    </div>
                </div>
            </div>
            <div class="col-md-3 float-right">
                <b-dropdown
                    variant="info"
                    class="mt-2 float-right"
                >
                    <template #button-content>
                      Download Report
                    </template>
                    <b-dropdown-item @click="downloadReport('excel')">
                      Excel
                    </b-dropdown-item>
                    <b-dropdown-item @click="downloadReport('pdf')">
                      PDF
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <div class="col-md-12 mt-1 report">
                <div v-if="isLoading" class="text-center">
                    <b-spinner
                        class="mb-1"
                        variant="primary"
                    /><br>
                    Loading...
                </div>
                <div class="table-responsive" v-html="contentHtml"></div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import FileSaver from 'file-saver'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import _ from 'lodash'
import vSelect from 'vue-select'
import axios from "axios";
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
    title () {
        return `Asset Stock Report`
    },
    components: {
        DatePicker,
        BDropdown,
        BDropdownItem,
        vSelect,
        BSpinner
    },
    watch: {
        filter: {
            handler: function() {
                this.downloadReport('view')
            },
            deep: true
        }
    },
    setup(props) {
        return {
            checkPermission,
            successNotification,
            errorNotification,
        }
    },
    data() {
        return {
            filter: {
                start_date: '',
                end_date: '',
                filter: 'excel',
                trader_id: '',
            },
            users: [],
            contentHtml: null,
            requests: [],
            request: null,
            isLoading: false,
        }
    },
    mounted(){
        this.executeSearch('')

        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        this.filter.start_date = date
        this.filter.end_date = date

        this.downloadReport('view')
    },
    methods: {
        cancel() {
            this.request.cancel();
            this.clearOldRequest("Cancelled");
        },
        logResponseErrors(err) {
            if (axios.isCancel(err)) {
                console.log("Request cancelled");
            }
        },
        clearOldRequest(msg) {
            this.request.msg = msg;
            this.requests.push(this.request);
            this.request = null;
        },
        executeSearch(query){
            var vm = this
            this.$http.get('/admin/users?user_type=normal&per_page=200&sort_by_register=desc&keyword=' + escape(query))
                .then(response => {
                    vm.users = [];
                    response.data.data.data.forEach(function(user){
                        vm.users.push({
                            'id': user.id,
                            'label': user.code + ' (' + user.name + ')'
                        })
                    });

            })
        },
        onSearchUser(search, loading) {
            if(search.length) {
                loading(true);
                this.searchUser(loading, search, this);
            }
        },
        searchUser: _.debounce((loading, search, vm) => {
            vm.executeSearch(escape(search))
            loading(false);
        }, 300),
        downloadReport(type = 'view') {
            var context = this
            context.filter.report_type = type

            if (this.request) this.cancel();

            if (type == 'view') {
                context.isLoading = true
            }

            const axiosSource = axios.CancelToken.source();
            this.request = { cancel: axiosSource.cancel, msg: "Loading..." };

            this.$http.get('/admin/report/commodity-stock', {
                responseType: type == 'view' ? '' : 'blob',
                params: this.filter,
                cancelToken: axiosSource.token
            }).then(response => {

                if (type == 'view') {
                    context.contentHtml = response.data.data.html
                } else {

                    var timestamp = Math.floor(Date.now() / 1000);
                    if (type == 'excel') {
                        var fileName = 'commodity-stock-' + timestamp  + '.xlsx';
                    }

                    if (type == 'pdf') {
                        var fileName = 'commodity-stock-' + timestamp  + '.pdf';
                    }

                    FileSaver.saveAs(response.data, fileName);

                }

                context.isLoading = false

            }).catch(error => {
                if (error.response.data.meta.messages.length > 0) {
                    errorNotification(this, 'Oops!', error.response.data.meta.messages)   
                }

                context.isLoading = false

            })
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>